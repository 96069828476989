export type InviteEvents = "COMPLETED"
export type InviteStatus = "COMPLETED" | "TERMINATED" | "OVER_QUOTA"
export type ProjectType =
  | "QUANT_INTERNET_SAMPLE_ONLY"
  | "QUANT_INTERNET_FULL_SERVICE"
  | "QUANT_FREE_PULSE"
  | "QUANT_PAID_PULSE"
  | "QUANT_RE_CONTACTS"
  | "QUANT_PROGRAM_ONLY"
  | "QUAL_PRE_TESTS"
  | "QUAL_FOLLOW_UPS"
  | "QUAL_1_1_VIRTUAL"
  | "QUAL_1_1_IN_PERSON"
  | "QUAL_RE_CONTACTS"
  | "QUAL_ONLINE_FOCUS_GROUP"
  | "QUAL_ONLINE_BULLETIN_BOARD"
  | "QUAL_IN_PERSON_FOCUS_GROUP"

export type ProjectStatus =
  | "AWARDED"
  | "PROGRAMMING"
  | "TESTING"
  | "LIVE"
  | "CANCELED"
  | "PAUSED"
  | "SENDING_PAUSED"
  | "CLOSED"

export type CampaignStatus =
  | "DRAFT"
  | "READY_TO_LAUNCH"
  | "IN_PROGRESS"
  | "INVITES_SENT"
  | "PAUSED"
  | "SENDING_PAUSED"
  | "STOPPED"
  | "COMPLETED"

export type SurveyType = "THIRD_PARTY" | "DECIPHER"

export class Invite {
  isDeclined: boolean
  isInvited: boolean
  id?: string
  isSurveyAvailable: boolean
  projectStatus?: ProjectStatus
  incentive: number
  surveyLink: string
  surveyLength: number
  panelist: boolean
  openEnrolment: boolean
  projectType: ProjectType
  topic: string
  status?: InviteStatus
  campaignStatus: CampaignStatus
  surveyType: SurveyType

  constructor(props: any) {
    this.set(props)
  }

  set(props: any): void {
    this.setDefaultData()

    if (!props) return

    this.isDeclined = props.isDeclined ?? false
    this.isInvited = props.isInvited
    this.isSurveyAvailable = props.isSurveyAvailable
    this.incentive = props.incentive ?? 0
    this.id = props.id
    this.projectStatus = props.projectStatus ?? "LIVE"
    this.projectType = props.projectType ?? ""
    this.surveyLink = props.surveyLink ?? ""
    this.surveyLength = props.surveyLength ?? 0
    this.topic = props.topic ?? ""
    this.openEnrolment = props.openEnrolment ?? ""
    this.status = props.status
    this.panelist = props.panelist
    this.surveyType = props.surveyType
    this.campaignStatus = props.campaignStatus ?? "IN_PROGRESS"
  }

  setDefaultData(): void {
    this.isDeclined = false
    this.isInvited = true
    this.isSurveyAvailable = true
    this.id = ""
    this.incentive = 0
    this.surveyLink = ""
    this.surveyLength = 0
    this.topic = ""
    this.openEnrolment = false
    this.projectType = "QUANT_INTERNET_FULL_SERVICE"
    this.panelist = false
    this.campaignStatus = "IN_PROGRESS"
    this.projectStatus = "LIVE"
    this.surveyType = "DECIPHER"
  }

  get isAlreadyCompleted(): boolean {
    return this.isInvited && this.status === "COMPLETED"
  }

  get isAlreadyTerminated(): boolean {
    return this.isInvited && this.status === "TERMINATED"
  }

  get isAlreadyOverQuota(): boolean {
    return this.isInvited && this.status === "OVER_QUOTA"
  }

  get isNotPulse(): boolean {
    return this.projectType !== "QUANT_FREE_PULSE"
  }

  get showScreener(): boolean {
    // if project type is "QUANT_FREE_PULSE" dont show screener flow
    // if survey type is "Third Party" dont show screener flow
    return this.isNotPulse && this.surveyType !== "THIRD_PARTY"
  }

  get showOnlyTermsPage(): boolean {
    return this.isNotPulse
  }

  get showAdditionalInfo(): boolean {
    // need update when we have additional info on campaign page
    return false
  }

  get isPanelist(): boolean {
    return this.panelist
  }

  get isDecipher(): boolean {
    return this.surveyType === "DECIPHER"
  }

  get showJoinToPortal() {
    const statuses = ["COMPLETED", "TERMINATED", "OVER_QUOTA"]

    return (
      this.openEnrolment &&
      statuses.includes(this.status ?? "") &&
      this.panelist === false
    )
  }

  get isUnpaid(): boolean {
    return this.incentive === 0
  }

  static create(props: any): Invite {
    return new Invite(props)
  }
}
